<template>
  <div class="cadastro-usuario">
    <v-container
      fluid>
      <h1 class="titulo">Cadastro de Supervisor</h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage">
            
            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: item, getItens: getItens, jsonData: true }" />

               <!-- <v-toolbar flat color="white">
                <ModalCadastro />
                <v-spacer></v-spacer>

                <v-col cols="6" sm="6" md="3">
                  <v-text-field
                    append-icon="search"
                    label="Busca"
                    single-line
                    hide-details
                    v-on:keyup.enter="$emit('search', search)"
                    v-model="search"
                    color="primary" />
                  <BrSearchDataTable @search="buscar" />
                </v-col>
                <ModalFiltro :listaSelect="item" :getItens="getItens" :jsonData="true" />
              </v-toolbar> -->
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'view')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>

              <v-btn
                v-if="permiteAcao($route, 'delete')"
                small
                @click="deleteItem(item)"
                tile
                outlined 
                :color="variables.colorError"
              >
                Excluir
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'CadastroOSC',
  mixins: [listagemTables],
  components: {
    BrToolBar: () => import('./components/toolbar')
    // ModalCadastro: () => import('./components/modalCadastro'),
    // ModalFiltro: () => import('./components/modalFiltro')
  },
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'start', class: 'table-header', text: 'Telefone', value: 'phone' },
      { align: 'start', class: 'table-header', text: 'Responsavel', value: 'responsible.name' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    typeBusca: 'entities'
  }),
  computed: {
    ...mapGetters('osc', ['listaItens', 'totalItens', 'item']),
  },

  methods: {
    ...mapActions('osc', ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens'])
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>>
